import moment, { type Moment } from "moment";
import type { NamespacePortfolioOpex, NamespacePortfolioPropertyOpex } from "../user-preferences-helpers";
import { type CategoryCell, fitDateString, getDateRange, getDateRangeEntries } from "./portfolio-balance-helpers";
import { Currency } from "../apollo/types";
import { arrayLast, hasValue } from "../common-helpers";
import type { OpexData } from "~/composables/queries/useOpexQuery";
import { AssetManagementPropertySubType, MetricPeriodType } from "~/graphql/generated/graphql";
import { getCategories as getCategoriesBase, type Budget, type CategoryRow } from "./portfolio-category-helpers";
import { Category, getPropertyArea, TenanciesEntity } from "./portfolio-property-helpers";
import { PortfolioPropertyOpexCategoryData } from "~/pages/portfolio/components/Expenses/Opexes/helpers";

export type OpexCategoryDataPropertyPointValue = { accumulated: number; accumulatedAnnualised: number; value: number; valueAnnualised: number };
export type OpexCategoryDataPointValue = { accumulated: number; value: number };

export type OpexCategoryBenchmarkDataPoint = {
  result: OpexCategoryDataPointValue;
  budget: OpexCategoryDataPointValue;
  average: OpexCategoryDataPointValue;
};

export type OpexCategoryDataProperty = { id: string; name: string; currency: Currency; values: OpexCategoryDataPropertyPointValue[]; accountHashes: string[] };
export type CategoryData = { id: string; name: string; currency: Currency; values: OpexCategoryDataPointValue[]; accountHashes: string[] };

export type OpexCategoryData = {
  id: string;
  name: string;
  currency: Currency;

  columns: {
    result: OpexCategoryDataPointValue[];
    budget: OpexCategoryDataPointValue[];
  };
};
export type OpexCategoryBenchmarkData = {
  id: string;
  name: string;
  currency: Currency;

  columns: {
    result: OpexCategoryDataPropertyPointValue[];
    average: OpexCategoryDataPropertyPointValue[];
  };
};

export const getOpexCategoryData = (
  columns: Moment[],
  result: OpexData,
  budget: OpexData,
  preferences: { periodType: MetricPeriodType; normaliseBy: "none" | "area" | "tenancies"; includeZeroRows?: boolean },
  categories: Category[],
  includeTotal = false
): OpexCategoryData[] => {
  const periodType = preferences.periodType;

  const getCategories = (data: CategoryCell[]) => getCategoriesBase(data, columns, new Map(), periodType, true, "results");

  const getOpexDataRow = (categoryRow: CategoryRow): CategoryData => ({
    id: categoryRow.id,
    currency: categoryRow.currency,
    name: categoryRow.name,
    accountHashes: categoryRow.hashes,
    values: categoryRow.values.map((v) => {
      const value = v.totalCostWithVat;

      return {
        value,
        accumulated: 0,
      };
    }),
  });

  /** Accumulates column values for a category row + the amount of 12 month periods of current accumulation. Will extrapolate if less than 12 */
  const accumulateRow = (row: CategoryData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];
      const prev = row.values[i - 1];

      current.accumulated = current.value + (prev?.accumulated ?? 0);
    }
  };

  const categoryIds = new Set(categories.map((c) => c.id));

  /** Iterate through all "cells" which hold accumulated data for a category in period  */
  const getCategoryRows = (data: OpexData) => {
    data = { ...data, cells: data.cells?.filter((c) => categoryIds.has(c.categoryId)) };

    const rows = data.cells ? getCategories(data.cells).map((row) => getOpexDataRow(row)) : [];

    if (includeTotal) {
      rows.push({
        currency: rows[0]?.currency ?? "DKK",
        id: "total",
        name: "Total",
        accountHashes: [],
        values: rows.reduce((prev, next) => {
          for (let i = 0; i < next.values.length; i++) {
            if (!hasValue(prev[i])) prev[i] = { accumulated: 0, value: 0 };

            prev[i].value += next.values[i].value;
          }

          return prev;
        }, [] as OpexCategoryDataPointValue[]),
      });
    }

    rows.forEach(accumulateRow);

    return rows;
  };

  const resultRows = getCategoryRows(result);
  const budgetRows = getCategoryRows(budget);
  // const averageRows = getCategoryRows(benchmark);

  /** Merging rows for benchmark */

  const getDefaultDataPoint = () => ({ accumulated: 0, value: 0 });

  const benchmarkDataRows: OpexCategoryData[] = [];

  for (let i = -1; i < categories.length; i++) {
    if (i === -1 && !includeTotal) continue;

    const category = i === -1 ? { id: "total", name: "Total" } : categories[i];

    // const averageRow = averageRows.find((c) => c.id === category.id);
    const resultRow = resultRows.find((c) => c.id === category.id);
    const budgetRow = budgetRows.find((c) => c.id === category.id);

    benchmarkDataRows.push({
      currency: resultRow?.currency!,
      id: category.id,
      name: category.name ?? "",
      columns: {
        result: columns.map((_, index) => resultRow?.values[index] ?? getDefaultDataPoint()),
        budget: columns.map((_, index) => budgetRow?.values[index] ?? getDefaultDataPoint()),
      },
    });
  }

  benchmarkDataRows.sort((a, b) => (a.id === "total" ? 1 : a.name.localeCompare(b.name)));

  return benchmarkDataRows;
};

export const getOpexCategoryChartColumns = (preferences: NamespacePortfolioPropertyOpex) => {
  const withBudget = false;
  const periodType = withBudget ? MetricPeriodType.Monthly : preferences.periodType;

  const dateRange = getDateRange(preferences.dateRange, preferences.periodType, preferences.customDateStart, preferences.customDateEnd);

  return getDateRangeEntries(dateRange, periodType);
};

export const getOpexCategoryBenchmarkData = (
  columns: Moment[],
  result: OpexData,
  average: OpexData,
  preferences: { periodType: MetricPeriodType; normaliseBy: "none" | "area" | "tenancies"; includeZeroRows?: boolean },
  categories: Category[]
) => {
  const periodType = preferences.periodType;

  const periodTypeMonthCount = periodType === MetricPeriodType.Monthly ? 1 : periodType === MetricPeriodType.Quarterly ? 3 : 12;

  const normaliseByField: keyof OpexData["entityData"] | null =
    preferences.normaliseBy === "area" ? "totalArea" : preferences.normaliseBy === "tenancies" ? "totalTenancies" : null;

  const getMonthDiff = (index: number) => Math.abs(columns[0].diff(columns[index], "months")) + periodTypeMonthCount;

  const getCategories = (data: CategoryCell[]) => getCategoriesBase(data, columns, new Map(), periodType, true, "results");

  const getOpexDataRow = (categoryRow: CategoryRow): OpexCategoryDataProperty => ({
    id: categoryRow.id,
    currency: categoryRow.currency,
    name: categoryRow.name,
    accountHashes: categoryRow.hashes,
    values: categoryRow.values.map((v) => {
      const value = v.totalCostWithVat;

      return {
        value,
        accumulated: 0,
        accumulatedAnnualised: 0,
        valueAnnualised: 0,
      };
    }),
  });

  /** Normalises a category row by selected unit + create annualised value */
  const normaliseRow = (row: OpexCategoryDataProperty, data: OpexData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];

      current.value /= (normaliseByField ? data.entityData[normaliseByField] : data.entityCount) || 1;

      current.valueAnnualised = current.value * (12 / periodTypeMonthCount);
    }
  };

  /** Accumulates column values for a category row + the amount of 12 month periods of current accumulation. Will extrapolate if less than 12 */
  const accumulateRow = (row: OpexCategoryDataProperty) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];
      const prev = row.values[i - 1];

      const monthDiff = getMonthDiff(i);

      const annualiseFactor = 12 / monthDiff;

      current.accumulated = current.value + (prev?.accumulated ?? 0);

      current.accumulatedAnnualised = current.accumulated * annualiseFactor;
    }
  };

  const categoryIds = new Set(categories.map((c) => c.id));

  /** Iterate through all "cells" which hold accumulated data for a category in period  */
  const getCategoryRows = (data: OpexData) => {
    data = { ...data, cells: data.cells?.filter((c) => categoryIds.has(c.categoryId)) };

    const rows = data.cells ? getCategories(data.cells).map((row) => getOpexDataRow(row)) : [];

    rows.push({
      currency: rows[0]?.currency ?? "DKK",
      id: "total",
      name: "Total",
      accountHashes: [],
      values: rows.reduce((prev, next) => {
        for (let i = 0; i < next.values.length; i++) {
          if (!hasValue(prev[i])) prev[i] = { accumulated: 0, accumulatedAnnualised: 0, value: 0, valueAnnualised: 0 };

          prev[i].value += next.values[i].value;
        }

        return prev;
      }, [] as OpexCategoryDataPropertyPointValue[]),
    });

    rows.forEach((row) => normaliseRow(row, data));

    rows.forEach(accumulateRow);

    return rows;
  };

  const resultRows = getCategoryRows(result);
  const averageRows = getCategoryRows(average);

  /** Merging rows for benchmark */

  const getDefaultDataPoint = () => ({ accumulated: 0, value: 0, accumulatedAnnualised: 0, valueAnnualised: 0 });

  const benchmarkDataRows: OpexCategoryBenchmarkData[] = [];

  for (let i = -1; i < categories.length; i++) {
    const category = i === -1 ? { id: "total", name: "Total" } : categories[i];

    const averageRow = averageRows.find((c) => c.id === category.id);
    const resultRow = resultRows.find((c) => c.id === category.id);

    benchmarkDataRows.push({
      currency: resultRow?.currency!,
      id: category.id,
      name: category.name ?? "",
      columns: {
        result: columns.map((_, index) => resultRow?.values[index] ?? getDefaultDataPoint()),
        average: columns.map((_, index) => averageRow?.values[index] ?? getDefaultDataPoint()),
      },
    });
  }

  benchmarkDataRows.sort((a, b) => (a.id === "total" ? 1 : a.name.localeCompare(b.name)));

  return benchmarkDataRows;
};

export const benchmarkOptionGroups = [
  {
    id: "average",
    options: [
      {
        id: "average.total",
      },
    ],
  },
  {
    id: "budget",
    options: [
      {
        id: "budget.diff",
      },
    ],
  },
] as const;

export type BenchmarkGroup = (typeof benchmarkOptionGroups)[number];
export type BenchmarkGroupOption = BenchmarkGroup["options"][number]["id"];

export const getPropertiesAverage = (
  properties: (TenanciesEntity & { subType: AssetManagementPropertySubType; id: string; categoryCells?: Nullable<CategoryCell[]> })[]
) => {
  const benchmarkOpexData: OpexData = {
    cells: properties.reduce((prev, next) => {
      prev.push(...(next.categoryCells as CategoryCell[]));
      return prev;
    }, [] as CategoryCell[]),
    entityData: properties.reduce(
      (prev, next) => {
        const totalTenancies = next.assetManagementTenancies?.items?.length ?? 0;
        const totalArea = getPropertyArea(next);

        prev.totalArea += totalArea.area;
        prev.totalTenancies += totalTenancies;

        if (!hasValue(prev.areaUnit) && hasValue(totalArea.areaUnit)) prev.areaUnit = totalArea.areaUnit;

        return prev;
      },
      { areaUnit: <Nullable<string>>undefined, averageArea: 0, totalArea: 0, totalTenancies: 0 }
    ),
    entityCount: properties.length,
  };

  const propertyCount = properties.filter((r) => r.subType === AssetManagementPropertySubType.Property).length;

  benchmarkOpexData.entityData.averageArea = propertyCount ? benchmarkOpexData.entityData.totalArea / propertyCount : 1;
  benchmarkOpexData.entityData.averageTenancies = propertyCount ? benchmarkOpexData.entityData.totalTenancies / propertyCount : 1;

  return benchmarkOpexData;
};

export const getPropertiesBudgetOpexData = (properties: ({ id: string } & TenanciesEntity)[], categoryIds: Set<string>, budgets: Budget[]) => {
  const totalTenancies = properties.reduce((value, property) => value + (property.assetManagementTenancies?.items?.length ?? 0), 0);
  const totalArea = properties.reduce(
    (value, property) => {
      const next = getPropertyArea(property);

      value.area += next.area;
      value.areaUnit ??= next.areaUnit;

      return value;
    },
    { area: 0, areaUnit: <Nullable<string>>undefined }
  );
  const cells: CategoryCell[] = [];

  const records =
    budgets.filter((b) => !!properties.find((property) => b.propertyId === property.id)).flatMap((b) => b.assetManagementBudgetRecords?.items) ?? [];

  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    const account = record?.assetManagementAccount;
    const category = account?.assetManagementCategory;

    if (!account || !category || !categoryIds.has(category.id)) continue;

    const cell: CategoryCell = {
      categoryId: category.id,
      categoryLevel: 0,
      categoryName: category.name ?? "",
      change: 0,
      changePercentage: 0,
      currency: record.currency ?? "DKK",
      hash: "",
      isBlueprint: false,
      order: 0,
      period: record.entryDate,
      totalCostWithVat: -1 * (record.actualCostWithVat ?? 0),
      parentCategoryId: category.parentCategoryId,
    };

    cells.push(cell);
  }

  const benchmarkOpexData: OpexData = {
    cells,
    entityData: {
      areaUnit: totalArea.areaUnit,
      averageArea: totalArea.area / properties.length,
      totalArea: totalArea.area,
      totalTenancies: totalTenancies,
      averageTenancies: totalTenancies / properties.length,
    },
    entityCount: 1,
  };

  return benchmarkOpexData;
};

export const getDiff = (target: number, benchmark: number) => target - benchmark;

export const getDiffPercentage = (target: number, benchmark: number) => {
  const diff = getDiff(target, benchmark);

  return Math.sign(diff) * Math.abs((diff * 100) / benchmark);
};

export type DataPointField = KeyOfType<OpexCategoryData["columns"], OpexCategoryDataPointValue[]>;
